/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { API_URL } from '../constants';
import { getAccessToken, getProjectId } from '../utils/localStorage';

const api = axios.create({
  baseURL: API_URL,
  // other configuration options...
});

api.interceptors.request.use(
  (config) => {
    const accessToken = getAccessToken();
    const projectId = getProjectId();

    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }

    if (projectId) {
      config.params = {
        projectId,
        ...config.params,
      };
    }

    return config;
  },
  (error) => Promise.reject(error),
);

api.interceptors.response.use(
  (response) => {
    return response?.data;
  },
  async (error) => {
    // const originalRequest = error.config;

    console.log(error);

    switch (error.response.status) {
      case 401: {
        break;
      }
      case 400: {
        break;
      }
      default: {
        /* empty */
      }
    }

    return Promise.reject(error);
  },
);

export default api;
