export const ACCESS_TOKEN_KEY = 'access_token';
export function getAccessToken() {
  return localStorage.getItem(ACCESS_TOKEN_KEY);
}
export function setAccessToken(token: string) {
  localStorage.setItem(ACCESS_TOKEN_KEY, token);
}

export function clearAccessToken() {
  localStorage.removeItem(ACCESS_TOKEN_KEY);
}

export const PROJECT_ID = 'project_id';
export function getProjectId() {
  return localStorage.getItem(PROJECT_ID);
}
export function setProjectId(projectId: string) {
  localStorage.setItem(PROJECT_ID, projectId);
}
