/* eslint-disable @typescript-eslint/no-explicit-any */
import api from './axios.config';

type List<T = any> = { list: T[]; totalCount: number };

type ProjectResponse = {
  createdAt: string;
  deletedAt: string;
  id: number;
  name: string;
  updatedAt: string;
};

// eslint-disable-next-line import/prefer-default-export
export const getProjectsRequest = (
  params = { skip: 0, take: 10, sort: 'id', order: 'ASC' },
) => api.get<any | undefined, List<ProjectResponse>>('/projects', { params });
