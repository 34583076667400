/* eslint-disable @typescript-eslint/no-explicit-any */
import api from './axios.config';

interface UserResponse {
  createdAt: string;
  deletedAt: string;
  email: string;
  firstName: string;
  googleId: string;
  id: number;
  lastName: string;
  status: string;
  type: string;
  updatedAt: string;
}

interface LoginGoogleResponse {
  token: string;
  user: UserResponse;
}
// eslint-disable-next-line import/prefer-default-export
export const loginGoogleRequest = (data: any) =>
  api.post<any, LoginGoogleResponse>(`/auth/google/login`, data);
