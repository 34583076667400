import { CredentialResponse, GoogleLogin } from '@react-oauth/google';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { loginGoogleRequest } from '../api/auth';
import { setAccessToken, setProjectId } from '../utils/localStorage';
import { getProjectsRequest } from '../api/projects';
import { useUserQuery } from '../hooks/useQuery';

function Login() {
  const navigate = useNavigate();
  const { refetch: refetchUser } = useUserQuery();

  const { mutate: getProjects } = useMutation({
    mutationFn: getProjectsRequest,
    onSuccess: (data) => {
      setProjectId(data.list[0]?.id.toString() || '1');
      refetchUser();
      navigate('/app', { replace: true });
    },
    onError: (error) => {
      console.error('Error fetching data:', error);
      refetchUser();
      setProjectId('1');
      navigate('/app', { replace: true });
    },
  });

  const { mutate: loginGoogle } = useMutation({
    mutationFn: loginGoogleRequest,
    onSuccess: (data) => {
      setAccessToken(data.token);
      getProjects(undefined);
    },
    onError: (error) => {
      console.error('Error fetching data:', error);
    },
  });

  const handleSuccess = async (cr: CredentialResponse) => loginGoogle(cr);
  const handleError = () => {
    console.log('Login Failed');
  };

  return (
    <div>
      <h2>Login with Google</h2>
      <GoogleLogin onSuccess={handleSuccess} onError={handleError} />
    </div>
  );
}

export default Login;
