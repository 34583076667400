// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*
 * @NOTE: Prepend a \`~\` to css file paths that are in your node_modules
 *        See https://github.com/webpack-contrib/sass-loader#imports
 */
body {
  position: relative;
  color: white;
  height: 100vh;
  background: linear-gradient(
    200.96deg,
    #fedc2a -29.09%,
    #dd5789 51.77%,
    #7a2c9e 129.35%
  );
  font-family: sans-serif;
  overflow-y: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

button {
  background-color: white;
  padding: 10px 20px;
  border-radius: 10px;
  border: none;
  appearance: none;
  font-size: 1.3rem;
  box-shadow: 0px 8px 28px -6px rgba(24, 39, 75, 0.12),
    0px 18px 88px -4px rgba(24, 39, 75, 0.14);
  transition: all ease-in 0.1s;
  cursor: pointer;
  opacity: 0.9;
}

button:hover {
  transform: scale(1.05);
  opacity: 1;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
  height: fit-content;
  width: fit-content;
  margin: 10px;
}

a:hover {
  opacity: 1;
  text-decoration: none;
}

.Hello {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}
`, "",{"version":3,"sources":["webpack://./src/renderer/App.css"],"names":[],"mappings":"AAAA;;;EAGE;AACF;EACE,kBAAkB;EAClB,YAAY;EACZ,aAAa;EACb;;;;;GAKC;EACD,uBAAuB;EACvB,kBAAkB;EAClB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,uBAAuB;EACvB,kBAAkB;EAClB,mBAAmB;EACnB,YAAY;EACZ,gBAAgB;EAChB,iBAAiB;EACjB;6CAC2C;EAC3C,4BAA4B;EAC5B,eAAe;EACf,YAAY;AACd;;AAEA;EACE,sBAAsB;EACtB,UAAU;AACZ;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,qBAAqB;EACrB,mBAAmB;EACnB,kBAAkB;EAClB,YAAY;AACd;;AAEA;EACE,UAAU;EACV,qBAAqB;AACvB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,cAAc;AAChB","sourcesContent":["/*\n * @NOTE: Prepend a `~` to css file paths that are in your node_modules\n *        See https://github.com/webpack-contrib/sass-loader#imports\n */\nbody {\n  position: relative;\n  color: white;\n  height: 100vh;\n  background: linear-gradient(\n    200.96deg,\n    #fedc2a -29.09%,\n    #dd5789 51.77%,\n    #7a2c9e 129.35%\n  );\n  font-family: sans-serif;\n  overflow-y: hidden;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\nbutton {\n  background-color: white;\n  padding: 10px 20px;\n  border-radius: 10px;\n  border: none;\n  appearance: none;\n  font-size: 1.3rem;\n  box-shadow: 0px 8px 28px -6px rgba(24, 39, 75, 0.12),\n    0px 18px 88px -4px rgba(24, 39, 75, 0.14);\n  transition: all ease-in 0.1s;\n  cursor: pointer;\n  opacity: 0.9;\n}\n\nbutton:hover {\n  transform: scale(1.05);\n  opacity: 1;\n}\n\nli {\n  list-style: none;\n}\n\na {\n  text-decoration: none;\n  height: fit-content;\n  width: fit-content;\n  margin: 10px;\n}\n\na:hover {\n  opacity: 1;\n  text-decoration: none;\n}\n\n.Hello {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  margin: 20px 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
