import { QueryClient, useQuery } from 'react-query';
import { getAuthUserRequest } from '../api/users';

export const createQueryClient = () =>
  new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retryOnMount: false,
        retry: false,
      },
    },
  });

export const useUserQuery = () => useQuery('user', getAuthUserRequest);
