import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useUserQuery } from '../hooks/useQuery';

function UnauthorizedUserRoute(): React.JSX.Element {
  const { isSuccess } = useUserQuery();
  return isSuccess ? <Navigate to="/app" /> : <Outlet />;
}

export default UnauthorizedUserRoute;
