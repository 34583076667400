import { Route, Routes } from 'react-router-dom';
import PrivateRoute from './screens/PrivateRoute';
import Login from './screens/Login';
import UnauthorizedUserRoute from './screens/UnauthorizedUserRoute';

function Routing() {
  return (
    <Routes>
      <Route element={<UnauthorizedUserRoute />}>
        <Route path="*" element={<Login />} />
        <Route path="/login" element={<Login />} />
      </Route>
      <Route element={<PrivateRoute />}>
        <Route path="/app" element={<div>Hello</div>} />
      </Route>
    </Routes>
  );
}

export default Routing;
