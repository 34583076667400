import { BrowserRouter } from 'react-router-dom';
import { QueryClientProvider } from 'react-query';
import './App.css';
import { GoogleOAuthProvider } from '@react-oauth/google';
import Routing from './Routing';
import { createQueryClient } from './hooks/useQuery';
import { GOOGLE_CLIENT_ID } from './constants';

export default function App() {
  const queryClient = createQueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID || ''}>
        <BrowserRouter>
          <Routing />
        </BrowserRouter>
      </GoogleOAuthProvider>
    </QueryClientProvider>
  );
}
